<template>
  <div class="viewBox">
    <!-- <div class="link"></div> -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="关注" name="first"> </el-tab-pane>
      <el-tab-pane label="粉丝" name="second"> </el-tab-pane>
    </el-tabs>

    <box-1-item
      @shuax="getdata()"
      :item="item"
      :followed="item.followed"
      v-for="(item, index) in store.gzFsList"
      :key="index"
    ></box-1-item>
    <!-- <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination> -->
  </div>
</template>
<script>
import Store from "../../store";
// import Store from "..==/../store";
import box1Item from "./box1-item.vue";
export default {
  components: { box1Item },
  mounted() {
    // 获取粉丝列表
    // this.getFSlist();
    // 获取关注列表
    this.getGZlist();
    // console.log("123456");
  },
  data() {
    return {
      activeName: "first",
      store: Store.state,
      uti: Store.uti,
      act: 0,
      loading: false,
      finished: false,
      total: 100,
      pageNum: 1,
      pageSize: 10,
    };
  },
  methods: {
    // 选中了某一项
    handleClick(tab, event) {
      console.log(tab, event);
      this.act = tab.index;
      this.getdata();
    },
    // 获取数据
    getdata() {
      if (this.act == 0) {
        this.getGZlist();
      } else {
        this.getFSlist();
      }
    },
    // 获取我的粉丝
    async getFSlist() {
      let { data: res } = await this.$http.post(`/userFollow/followMe`, {
        pageNum: 1,
        pageSize: 1000,
        userId: this.store.user.userId,
      });
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.store.gzFsList = res.data.records;
    },
    // 获取我的关注
    async getGZlist() {
      let { data: res } = await this.$http.post(`/userFollow/myFollowUser`, {
        pageNum: this.pageNum,
        pageSize: 999,
        userId: this.store.user.userId,
      });
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.store.gzFsList = res.data.records;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getdata();
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push({
            address: "123456", //	被关注用户地址	string
            followed: 1, //	已关注对方 1.是 0.否	integer
            id: "123456798", //	被关注用户id	integer
            nickName: "张三", //	被关注用户昵称	string
          });
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.store.gzFsList.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.viewBox {
  margin: 0 (18 / 3.75vw);
  margin-bottom: 30vh;
  .el-pagination {
    margin-top: 20px;
  }
  /deep/ .el-tabs__nav.is-top {
    width: 100%;
    // background-color: red;
    text-align: center;
    display: flex;
    justify-content: space-around;
    .el-tabs__active-bar.is-top {
      display: none;
    }
    .el-tabs__item {
      padding: 0;
    }
    .is-active {
      // border-bottom: 2px solid #000;
      color: #ff7d41;
    }
  }
}
</style>
